@import "index";

body > header, article, body > footer {
  padding: 1.5em 1em; }

@media (min-width: 40em) {
  body > header, article, body > footer {
    padding: 1.5em calc(34% - 12rem); } }



body > header {
  background: #2b2b2b;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, .05); }




