$font-size: calc(0.7rem + 0.25vw) !default;
$font-weight: 400 !default;
$heading-weight: 400 !default;
$line-height: 1.65 !default;
$dark: #111 !default;
$blue: #174c80 !default;
$light: white !default;
$alert: #EB811B !default;
$link-color: $dark !default;

@function reduce ($percent) {
  @return rgba(mix($dark, $light), $percent / 100); }

@function reducealert ($percent) {
  @return rgba(mix($alert, $light), $percent / 100); }

@import "basic", "highlight", "layout", "darkmode";

.light {
  background: $light;
  color: $dark; }

.dark {
  background: $dark;
  color: $light; }
