body > header, body > footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center; }

header h1 {
  margin: 0; }

nav {
  margin: 0 -.8em; }

nav a {
  padding: 0 .8em; }
